'use client';

import { useRouter } from 'next/navigation';
import React, { Suspense } from 'react';
import styles from '../styles/Home.module.css';
import {generateRoomId} from "@/lib/client-utils";


function ConnectionForm() {
  const router = useRouter();

  const onSubmit = () => {
    router.push(`/rooms/${generateRoomId()}`);
  }
  return (
        <button
            style={{paddingInline: '1.25rem', width: '100%'}}
            className="lk-button"
            onClick={onSubmit}
        >
          Connect
        </button>
  );
}

export default function Page() {
  return (
    <>
      <main className={styles.main} data-lk-theme="default">
        <div className="header">
          <h2>
            Video conferencing app built for <span style={{color: 'cyan'}}>Staq</span>.
          </h2>
          <h3 className={'header'} style={{fontSize: '14px'}}>{'You should thank Shamil for spending his own money for the sake of his friends and brother Zelim (not Lema)'}</h3>
        </div>
        <Suspense fallback="Loading">
          <ConnectionForm />
        </Suspense>
      </main>
    </>
  );
}
